jQuery(function($) {
    $('#main-menu').each(function(){
        var $me = $(this);

        $me.find('.menu-item').hover(function(){
            clearTimeout(window._timer_sub_menu);

            // find previous menus
            $(this).prevAll().find('.sub-menu').hide();
            $(this).prevAll().removeClass('hover');
            // find next menus
            $(this).nextAll().find('.sub-menu').hide();
            $(this).nextAll().removeClass('hover');

            if( $(this).find('>.sub-menu').length == 0 )
                return;

            $(this).find('>.sub-menu').slideDown();
            $(this).addClass('hover');


        }, function(){

        });

        $me.hover(function(){

        }, function(){
            window._timer_sub_menu = setTimeout(function(){
                $('#main-menu .sub-menu').hide();
                $('#main-menu .menu-item').removeClass('hover');
            }, 500);

        });

        $me.find('.current-menu-item').each(function(){
            $(this).parents('.menu-item').addClass('current-menu-item');
        });
    });

    $('#menu-responsive-button').each(function(){
        $(this).click(function($e){
            var $menu = $('#main-menu .menu-container');
            $menu.slideToggle();

            $e.stopPropagation();
            return false;
        });


        $(document).on('click', function(){
            var $menu = $('#main-menu .menu-container');
            if( $('#menu-responsive-button').is(':visible') && $menu.is(':visible') ){
                $menu.hide();
            }
        });
    });
    /*
     $("img.lazy").lazyload(
     {
     'placeholder':''
     }
     );*/

    /*********************************************************/
    if($.fn.bxSlider){
        preload_pictures($('#main-banner .bxslider'), function(){
            var $slider = $('#main-banner .bxslider');
            $slider.removeClass('loading');
            if($slider.find('li').length > 1) {
                var $mode = $slider.data('mode');
                var $pause = $slider.data('pause');
                if(!$mode)
                    $mode = 'horizontal';
                if(!$pause)
                    $pause = 5000;
                else
                    $pause = parseInt($pause);
                var $bx = $slider.bxSlider({
                    mode: $mode,
                    auto: true,
                    pause:  $pause,
                    touchEnabled: false
                });

                $bx.closest('.bx-wrapper').find('.bx-pager a').click(function(){
                    var i = $(this).parent().index();
                    $slider.goToSlide(i);
                    $slider.stopAuto();
                    restart = setTimeout(function(){
                        $slider.startAuto();
                    }, 500);
                    return false;

                });

                $bx.closest('.bx-wrapper').find('.bx-controls-direction a').click(function(){
                    $slider.stopAuto();
                    restart = setTimeout(function(){
                        $slider.startAuto();
                    }, 500);
                    return false;

                });
            }
        });
    }

    if($.fn.bxSlider){
        $('.scroll-section .bxslider').each(function(){
            var $slider = $(this);
            preload_pictures($slider, function(){
                $slider.removeClass('loading');
                if($slider.find('.post').length >1){
                    var $mode = $slider.data('mode');
                    var $auto = $slider.data('auto');
                    var $controls = $slider.data('controls');
                    var $pager = $slider.data('pager');
                    var $pause = $slider.data('pause');
                    var $max = $slider.data('max');
                    var $min = $slider.data('min');
                    var $move = $slider.data('move');
                    var $width = $slider.data('width');

                    $mode = $mode ? $mode : 'horizontal';
                    $auto = $auto ? 1 : 0;
                    $controls = $controls ? 1 : 0;
                    $pager = $pager ? 1 : 0;
                    $pause = $pause ? parseInt($pause) : 8000;
                    $max = $max ? $max : 1;
                    $min = $min ? $min : 2;
                    $move = $move ? $move : 0;
                    $width = $width ? $width : 0;
                    var $bx_params = {
                        mode: $mode,
                        auto: $auto,
                        autoHover: true,
                        pause:  $pause,
                        touchEnabled: false,
                        controls: $controls,
                        pager: $pager
                    };


                    if($move) {
                        $bx_params.minSlides = $min;
                        $bx_params.maxSlides = $max;
                        $bx_params.moveSlides = $move;
                        $bx_params.slideMargin = 0;
                        if(!$width) {
                            $width = 200;
                            if($max == 2) {
                                $width = 570;
                            }else if($max == 3) {
                                $width = 380;
                            }else if($max == 4) {
                                $width = 285;
                            }else if($max == 6) {
                                $width = 190;
                            }
                        }
                        $bx_params.slideWidth = $width;
                    }
                    var $bx = $slider.bxSlider($bx_params);

                    if($auto) {
                        $bx.closest('.bx-wrapper').find('.bx-controls a').click(function(){
                            var i = $(this).parent().index();
                            $slider.goToSlide(i);
                            $slider.stopAuto();
                            restart = setTimeout(function(){
                                $slider.startAuto();
                            }, 500);
                            return false;
                        });
                    }
                }
            });
        });

    }

    if($.fn.fitVids){
        $('.featured-video').fitVids();
    }

    /*********************************************************/
    if($.fn.isotope && $('#grid-layout').length){
        preload_pictures($('#grid-layout'), function(){
            var $grid_options = {
                itemSelector : '.post',
                masonry: {
                    columnWidth: '.grid-sizer',
                    gutter: '.gutter-sizer'
                }
            };
            var $first_load = 1;
            var $item_grid = $('#grid-layout').isotope($grid_options);
            $item_grid.on('layoutComplete', function(isoInstance, laidOutItems){
                if($first_load == 1) {
                    var $duration = 300;
                    $.each(laidOutItems, function($idx, $item){
                        $($item.element).delay($idx*$duration).animate({'opacity':1}, function(){
                            $(this).addClass('loaded');
                        });
                    });
                    $first_load = 2;
                } else {
                    $.each(laidOutItems, function($idx, $item){
                        $($item).stop().addClass('loaded');
                    });
                }
            });
            $item_grid.isotope('bindResize');
            $item_grid.isotope('layout');

            $('#filter-bar a').click(function(){
                var $key = $(this).data('key');
                $key = $key ? '.' + $key : '*';

                $item_grid.isotope({filter:$key});

                $('#filter-bar li').removeClass('active');
                $(this).parent().addClass('active');

                return false;
            });
        });

        $('body').bind('load_more_posts', function($e, $document){
            var $items = $document.find('#grid-layout .post');
            $items.addClass('loaded');
            preload_pictures($items, function(){
                $('#grid-layout').isotope('insert', $items);
            });
        });
    }
    else if($('.posts-list.list-infinitive').length){
        $('body').bind('load_more_posts', function($e, $document){
            var $items = $document.find('.posts-list.list-infinitive .post');
            $('.posts-list.list-infinitive').append($items);
        });
    }

    $('body').on('click', '.navigation-more a', function(){
        var $navigation_more = $(this).closest('.navigation-more');
        var $next_url = $(this).data('next-url');
        if( !$next_url )
            $next_url = $('.navigation a.next').attr('href');

        if($next_url){
            $navigation_more.addClass('loading');
            $.get($next_url,function($response){
                var $documents = $($response);

                $('body').trigger('load_more_posts', [$documents]);

                $navigation_more.removeClass('loading');

                $next_url = $documents.find('.navigation a.next').attr('href');
                if(!$next_url){
                    $navigation_more.hide();
                }else{
                    $navigation_more.find('a').data('next-url', $next_url);
                }
            });
        }else{
            $navigation_more.hide();
        }

        return false;
    });


    /*********************************************************/
    $('.faq-view .post .question a').click(function(){
        var $parent = $(this).closest('.post');
        var $answer = $parent.find('.answer');
        $answer.slideToggle();
        $(this).parent().toggleClass('active');
        return false;
    });
    
    /*********************************************************/
    if($.fn.validate){
        $('#commentform.validate-form').validate({
            rules:{
                'author':'required',
                'email': 'required email',
                'comment': 'required'
            },
            messages: {
                'author':'Enter your name',
                'email':{
                    'required': 'Enter your e-mail',
                    'email': 'E-mail is invalid'
                },
                'comment': 'Enter the comment'
            }
        });
    }
});

function preload_pictures($container, $callback)
{
    if( $container.length == 0 )
        return;

    var $ = jQuery;
    $container.addClass('loading');

    var $imgs = [];
    $container.find('img').each(function(){
        var $src = $(this).attr('src');
        if( $src )
            $imgs.push( $src );
    });

    var defer = $.imgpreloader({
        paths: $imgs
    });

    defer.always(function($allImages, $properImages, $brokenImages){
        $container.removeClass('loading');
        $callback();
    });

};

(function($){
    $(function(){
        if( $('.scrollup').length == 0 ){
            $('body').append('<a href="#" class="scrollup">Scroll Up</a>')
        }

        $(window).scroll(function(){
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });

        $('.scrollup').click(function(){
            $("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });
    })
})(jQuery);